import logger from '../../../logger'
import { PODCAST, PROGRAM, SERIES, STANDALONE_PROGRAM, PODCAST_SEASON } from '../../FrontPage/helpers/plugTypes'

const generateUrl = plug => {
  switch (plug.type) {
    case PODCAST: {
      return `/podkast/${plug.podcast.id}`
    }
    case SERIES: {
      return `/serie/${plug.series.id}`
    }
    case PODCAST_SEASON: {
      return `/podkast/${plug.podcastSeason.podcastId}/sesong/${plug.podcastSeason.id}`
    }
    case STANDALONE_PROGRAM: {
      return `/program/${plug.standaloneProgram.id}`
    }
    case PROGRAM: {
      return `/program/${plug.program.id}`
    }
    default: {
      logger.warn('Missing url map for: ', plug)
    }
  }
}

export default generateUrl
