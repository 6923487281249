import React from 'react'
import PropTypes from 'prop-types'
import Text from '../../../RadioUI/Text'

const EpisodeDescription = ({ children, ...props }) => {
  return (
    <Text size="lg" pb="4" whiteSpace="pre-wrap" {...props}>
      {children}
    </Text>
  )
}

EpisodeDescription.propTypes = { children: PropTypes.string }

export default EpisodeDescription
