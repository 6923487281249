import React, { Fragment } from 'react'
import Box from '../../../RadioUI/Box'
import Text from '../../../RadioUI/Text'
import { useContributors, useEpisodeIsLoading } from '../hooks/episodeHooks'
import { css } from '@emotion/react'
import { useSeriesIsLoading } from '../hooks/seriesHooks'
import { useSeasonIsLoading } from '../hooks/seasonHooks'
import LoadingPlaceholder from '../../../RadioUI/LoadingPlaceholder/LoadingPlaceholder'

const EpisodeContributorsContainer = props => {
  const seriesIsLoading = useSeriesIsLoading()
  const seasonIsLoading = useSeasonIsLoading()
  const episodeIsLoading = useEpisodeIsLoading()
  const contributors = useContributors()

  if (seriesIsLoading || seasonIsLoading || episodeIsLoading) {
    return (
      <Box {...props}>
        <LoadingPlaceholder h="8" delay={200} />
        <LoadingPlaceholder h="32" delay={200} />
      </Box>
    )
  }

  if (!contributors || contributors.length < 1) return null

  return (
    <Box {...props}>
      <Text as="h3" size="xl" weight="semibold" pb="6">
        Medvirkende
      </Text>
      <Box
        as="dl"
        css={css`
          dt {
            float: left;
            clear: both;
            padding-right: 0.3rem;
          }
        `}
      >
        {contributors.map(({ role, name }) => (
          <Fragment key={`${role}_${name}`}>
            <Box as="dt">{role}:</Box>
            <Box as="dd">{name}</Box>
          </Fragment>
        ))}
      </Box>
    </Box>
  )
}

EpisodeContributorsContainer.propTypes = {}

export default EpisodeContributorsContainer
