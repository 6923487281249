import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Box from '../../../RadioUI/Box'
import PseudoBox from '../../../RadioUI/PseudoBox'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { hoverMovementStylesFromLg } from '../../../RadioUI/Plugs/styles/hoverMovementStyles'
import hoverColorStyles from '../../../RadioUI/Plugs/styles/hoverColorStyles'
import { plugButtonsHoverStyles } from '../../../RadioUI/Plug/PlugPlayPauseProgressButton/plugButtonStyles'
import { focusVisibleStyles } from '../../../RadioUI/Plugs/styles/focusStyles'
import Image from '../../../RadioUI/Image'
import Text from '../../../RadioUI/Text'
import PlugEpisodeMenu from '../../../RadioUI/Plug/PlugEpisodeMenu/PlugEpisodeMenu'
import { useColors } from '../../../RadioUI/theme/Color'
import { withImpressionAndClickTracking } from '../../../components/HOCs/withImpressionAndClickTracking'

const noop = () => {}

const EpisodeRecommendationItem = forwardRef(({ onClick = noop, ...restProps }, ref) => {
  const { linkUrl, title, recommendedId, type, imageUrl } = restProps
  const colors = useColors()
  return (
    <Box position="relative" w="49%" ref={ref}>
      <PseudoBox
        as={Link}
        to={linkUrl}
        aria-label={title}
        css={[hoverMovementStylesFromLg, hoverColorStyles(colors.main), focusVisibleStyles, plugButtonsHoverStyles]}
        onClick={() => onClick(restProps)}
      >
        <Image src={imageUrl} useIsInView={false} borderRadius="4px" />
        <Text mt="0.5rem" mb="1rem">
          {title}
        </Text>
      </PseudoBox>
      <PlugEpisodeMenu
        seriesId={recommendedId}
        seriesTitle={title}
        type={type}
        linkUrl={linkUrl}
        position="absolute"
        top="2px"
        right="1px"
      />
    </Box>
  )
})

EpisodeRecommendationItem.propTypes = {
  linkUrl: PropTypes.string,
  title: PropTypes.string,
  recommendedId: PropTypes.string,
  type: PropTypes.string,
  imageUrl: PropTypes.string,
  onClick: PropTypes.func
}

EpisodeRecommendationItem.displayName = 'EpisodeRecommendationItem'

export default withImpressionAndClickTracking(EpisodeRecommendationItem)
