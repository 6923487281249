import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchRecommendedByPodcastId } from '../../../apiClients/psapi'
import Box from '../../../RadioUI/Box'

import PseudoBox from '../../../RadioUI/PseudoBox'
import Text from '../../../RadioUI/Text'
import extractImageUrl from '../../FrontPage/helpers/extractImageUrl'
import generateUrl from '../helpers/generateUrl'
import { useSeriesId } from '../hooks/seriesHooks'
import EpisodeRecommendationItem from './EpisodeRecommendationItem'
import { useEpisodeIsLoading } from '../hooks/episodeHooks'

const EpisodeRecommendation = () => {
  const { seriesId: seriesIdFromParams } = useParams()
  const seriesIdFromState = useSeriesId()
  const [recommendations, setRecommendations] = useState(null)
  const isEpisodeLoading = useEpisodeIsLoading()

  useEffect(() => {
    if (seriesIdFromParams && seriesIdFromParams === seriesIdFromState && !isEpisodeLoading) {
      const abortController = new AbortController()
      fetchRecommendedByPodcastId({ contentId: seriesIdFromParams, maxNumber: 4 }, { signal: abortController.signal })
        .then(res => {
          setRecommendations(
            res?._embedded?.recommendations?.filter(
              recommended => !recommended?.type?.toLowerCase().includes('episode')
            ) || null
          )
        })
        .catch(() => {
          // Do nothing
          // TODO: This get fetched double and therefore cancelled once. To prevent double fetching,
          // the fetch and state should be moved up the component tree.
        })
      return () => {
        abortController.abort()
        setRecommendations(null)
      }
    }
  }, [isEpisodeLoading, seriesIdFromParams, seriesIdFromState])

  return (
    <>
      {recommendations?.length > 0 && (
        <Box>
          <Text as="h2" size="xl" weight="bold" mb="1rem" mt="2rem">
            Andre har også hørt
          </Text>
          <PseudoBox d="flex" flexDirection="row" flexWrap="wrap" justifyContent="space-between">
            {recommendations?.map((recommended, idx) => {
              if (recommended?.type?.toLowerCase().includes('episode')) {
                return null
              }
              const metaData = recommended[recommended.type]
              const imageUrl = extractImageUrl(metaData, 600)
              const linkUrl = generateUrl(recommended)
              if (!metaData) {
                return null
              }
              return (
                <EpisodeRecommendationItem
                  key={idx}
                  linkUrl={linkUrl}
                  title={metaData.titles?.title}
                  recommendedId={metaData.id}
                  type={recommended.type}
                  imageUrl={imageUrl}
                  contentTitle={recommended.upstreamSystemInfo.snowplow.title}
                  contentId={recommended.upstreamSystemInfo.snowplow.content.id}
                  contentKind={recommended.upstreamSystemInfo.snowplow.content.kind}
                  contentSource={recommended.upstreamSystemInfo.snowplow.content.source}
                  recommendationId={recommended.upstreamSystemInfo.snowplow.recommendationId}
                  imageId={recommended.upstreamSystemInfo.snowplow.imageId}
                  sectionId={recommended.upstreamSystemInfo.snowplow.section.id}
                  indexInSection={recommended.upstreamSystemInfo.snowplow.sectionIndex}
                  pageId={seriesIdFromState}
                />
              )
            })}
          </PseudoBox>
        </Box>
      )}
    </>
  )
}

EpisodeRecommendation.propTypes = {}

export default EpisodeRecommendation
